import React, { FunctionComponent as FC } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from 'react-helmet';
import Container from '@material-ui/core/Container';
import { getFromStorage } from '../util/storage-utils';
import ContentList from '../components/content-list';
import SlugLink from '../components/slug-link';
import BreadCrumb from '../components/pge-breadcrumb';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      paddingTop: theme.spacing(2.5),
      paddingBottom: theme.spacing(10),
    },
  }),
);

const Archive: FC = () => {
  const { page } = useStaticQuery(graphql`
    query archivePageContent {
      page: allContentfulPageNewsRelease(
        sort: { order: DESC, fields: releaseDate }
      ) {
        nodes {
          node_locale
          slug
          releaseTitle
          releaseDate(formatString: "YYYY-MM-DD")
        }
      }
    }
  `);
  const content = page.nodes.filter(
    (pg: any) =>
      pg.node_locale === (getFromStorage('locale') || 'en') &&
      pg.slug.match('placeholder') === null,
  );

  const rows = content.map((release: any) => {
    const newsRelease = (
      <SlugLink slug={release.slug} linkText={release.releaseTitle} />
    );
    return [release.releaseDate, newsRelease];
  });

  const breadcrumb = [
    { to: '/', label: 'Home' },
    { to: '', label: 'News' },
  ];

  const tableHeader = ['Date', 'News Release'];
  return (
    <>
      <Helmet bodyAttributes={{ class: 'TemplatePage' }}>
        <title>PGE Foundation - News Archive</title>
      </Helmet>
      <Container className={useStyles().main}>
        <BreadCrumb path={breadcrumb} />
        <ContentList
          rows={rows}
          tableHeader={tableHeader}
          heading="PGE Foundation News"
          introCopy={
            <span>
              <b>Recent media releases on PGE Foundation news and events</b>
            </span>
          }
        />
      </Container>
    </>
  );
};

export default Archive;
